import React, { useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from '@material-ui/icons/Person';

import {updateUser} from '../../reducers/user';
import { useDispatch, useSelector } from "react-redux";

const styles = theme => ({
    iconButton: {
        '& svg': {
            fontSize: 30
        }
    },
    login: {
        fontSize: 18,
        color: '#326dff',
        fontWeight: 500,
        lineHeight: 1.8,
        paddingLeft: 15,
        paddingRight: 30,
        textTransform: 'uppercase'
    },
    avatar: {
        color: '#fff',
        background: '#326dff',
        fontSize: 15,
        fontWeight: 500
    },
    logOut: {
        fontSize: 16,
        textAlign: 'center',
        color: '#F44336',
        fontWeight: 500,
    },
    menu: {
        minWidth: 200
    }
  });

const Profile = (props) => {

  const dispatch = useDispatch();

  const { user } = useSelector(state => ({
    user: state.user.profile,
  }));

  const { classes } = props;

  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);


     const handleOpenProfile = event => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
      };
    
      const handleRequestClose = () => {
        setOpen(false);
      };

      const logOut = () => {  
        localStorage.removeItem('@user');
        dispatch(updateUser(null));
      }

        return(
            <>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenProfile}
                size={'medium'}
                className={classes.iconButton}
              >
                <Avatar className={classes.avatar}>
                    <PersonIcon style={{ fontSize: 22 }}/>
                </Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleRequestClose}
                className={classes.menu}
                width={300}
              >
                <span className={classes.login}>{user.name}</span>
                <MenuItem className={classes.logOut} onClick={() => logOut()}>Выйти</MenuItem>
              </Menu>
              </>
        )
}

export default withStyles(styles)(Profile);
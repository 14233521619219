import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';

import user from './user'

let reducer = combineReducers({
    user,
    form: formReducer,
});

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

export default store;
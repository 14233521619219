import React from 'react';
import s from './login.module.css';
import Button from '../../components/Button';
import { Field, reduxForm } from 'redux-form';
import CustomizedInputs from '../../components/input';
import {required, email} from 'redux-form-validators';

const ResetForm = (props) => {
    return(
        <>
        <form className={s.form} onSubmit={props.handleSubmit}>
            <div className={s.title}>Сбросить пароль</div>
            <Field  name="email"
                    component={CustomizedInputs}
                    type="email"
                    placeholder="Email"
                    disabled={props.load ? true : false}
                    validate={[required({msg: 'Это поле обязательно для заполнения'}), email({msg: 'Введите корректный email'})]}/>
            <Button  load={props.load} 
                     handleClick={props.handleSubmit(props.resetPass)} 
                     title={'Сбросить'}
                     disabled={props.load}
                     size={22}
                     />
        </form>
        <div className={s.reset} onClick={() => props.statusRest()}>Авторизация</div>
        </>
    )
}

export default reduxForm({
    form: 'ResetForm', 
  })(ResetForm);


import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import {NavLink} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import withWidth from '@material-ui/core/withWidth';
import SettingsIcon from '@material-ui/icons/Settings';
import BarChartIcon from '@material-ui/icons/BarChart';
import CommentIcon from '@material-ui/icons/Comment';
import ContactsIcon from '@material-ui/icons/Contacts';

import {withRouter} from 'react-router-dom';

let Navigation = (props) => {

  const { classes, theme, width } = props;

  return(
    <Drawer
        className={classes.drawer}
        variant={width === 'sm' || width == 'xs' ? 'temporary' : 'persistent'}
        anchor="left"
        open={props.open}
        onClose={props.handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <span className={classes.headerLogo}>BOT ADMIN</span>
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </div>
      <Divider/>
        <List>
        <NavLink to='/reports' className={classes.link} activeClassName={classes.active}>
          <ListItem button>
            <ListItemIcon className={classes.iconMenu}><BarChartIcon/></ListItemIcon>
            <ListItemText primary={'Отчеты'} />
          </ListItem>
        </NavLink>
        <NavLink to='/staff' className={classes.link} activeClassName={classes.active}>
          <ListItem button>
            <ListItemIcon className={classes.iconMenu}><ContactsIcon/></ListItemIcon>
            <ListItemText primary={'Сотрудники'} />
          </ListItem>
        </NavLink>
        <NavLink to='/settings' className={classes.link} activeClassName={classes.active}>
          <ListItem button>
            <ListItemIcon className={classes.iconMenu}><SettingsIcon/></ListItemIcon>
            <ListItemText primary={'Настройки'} />
          </ListItem>
        </NavLink>
        <NavLink to='/logs' className={classes.link} activeClassName={classes.active}>
          <ListItem button>
            <ListItemIcon className={classes.iconMenu}><CommentIcon/></ListItemIcon>
            <ListItemText primary={'Логи'} />
          </ListItem>
        </NavLink>
        </List>
      </Drawer>
  )
}


const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  headerLogo: {
    fontSize: 18,
    fontWeight: 600,
    color: '#326dff',
    paddingLeft: 10,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  iconMenu: {
    minWidth: 34
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    display: 'flex',
    width: '100%',
    height: 'auto',
  },
  nestedLink: {
    fontSize: 14,
  },
  active: {
    color: '#326dff',
    background: '#eff3ff',
    '& .MuiListItemIcon-root': {
      color: '#326dff'
    },
  },
  nestedLink: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18
    },
    '& .MuiListItemIcon-root': {
      minWidth: 28,
    },
    '& .MuiList-padding': {
      padding: 0,
    },
    padding: 0,
  },
  collapse: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
  load: {
    width: '100%',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }
  });



export default withWidth()(withStyles(styles, { withTheme: true })(withRouter(Navigation)));

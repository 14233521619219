import React, {useState, forwardRef, useEffect, useRef} from 'react';
import MaterialTable from 'material-table';
import {actions} from '../../API/';
import {localization} from '../../constans/localization';
import Loading from '../../components/Preloader';
import AddButton from '../../components/addButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import StaffForm from './StaffForm'; 
 
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

export default function Users(props) {

  const tableRef = useRef();


  const  columns = [
    { title: 'ФИО', field: 'name' },
    { title: 'Номер телефона', field: 'phone', type: 'phone' },
    { title: 'Рейт, грн', field: 'rate'},
  ];

  const [isData, setIsData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    setIsLoad(true);
    actions.getWorkers()
      .then(res => {
        setIsData(res);
        setIsLoad(false);
      })
      .catch(err => {
        setIsLoad(false);
        alert(err);
      })
  }, []);

  const addData = (props) => {
      setIsLoad(true);
      actions.createWorker(props)
              .then(res => {
                  if(res.message === 'Сотрудник уже существует'){
                    alert(res.message)
                  } else {
                    setIsData([...isData, res]); 
                    setModalStatus(false);
                  }
                  setIsLoad(false);
              })
                .catch(err => {
                  setIsLoad(false);
                  setModalStatus(false);
              })
  }


  return (
    <>
    {isLoad && <Loading/>}
    {!modalStatus && <AddButton handleClick={() => setModalStatus(true)}/>}
      
      <StaffForm modalStatus={modalStatus} addData={addData} closeModal={() => setModalStatus(false)}/>

    <MaterialTable
      tableRef={tableRef}
      title="Сотрудники"
      columns={columns}
      data={isData}
      icons={tableIcons}
      editable={{
        onRowUpdate: (newData, oldData) => 
        new Promise((resolve, reject) => {
              let updateData = {};
              if(newData.name !== oldData.name){
                updateData.name = newData.name;
              };
              if(newData.phone !== oldData.phone){
                updateData.phone = newData.phone;
              };
              if(Number(newData.rate) !== Number(oldData.rate)){
                updateData.rate = Number(newData.rate);
              };
             if(Object.keys(updateData).length > 0){
                actions.editWorker({id: newData._id, edit: updateData})
                .then(res => {
                    const dataUpdate = [...isData];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setIsData([...dataUpdate]);
                    resolve();
                })
                .catch(err => {
                    resolve();
                })
              } else {
                resolve();
              }
        }),
        onRowDelete: (oldData) => 
        new Promise((resolve, reject) => {
              actions.deleteWorker({id: oldData._id})
                      .then(res => {
                          const dataDelete = [...isData];
                          const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setIsData([...dataDelete]);
                          resolve();
                      })
                      .catch(err => {
                          resolve();
                      })
      })
      }}

      localization={localization}
    />
    </>
  );
}
import * as axios from "axios";

const instance = axios.create({
    baseURL: 'https://mmgreports-api.qivisor.com',
    crossDomain: true
});

let user = JSON.parse(localStorage.getItem('@user'));


let jwt = null;
if(user){
    jwt = user.token;
}

export const actions = {
    auth(data){
        return instance.post(`auth`, {...data})
        .then (response => {
            return response.data;
        })
    },
    reset(data){
        return instance.post(`reset`, {...data})
        .then (response => {
            return response.data;
        })
    },
    getUsers(){
        return instance.get(`getUsers?jwt=${jwt}`)
        .then (response => {
            return response.data;
        })
    },
    createUser(data){
        return instance.post(`createUser`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    deleteUser(data){
        return instance.post(`deleteUser`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    deleteReport(data){
        return instance.post(`deleteReport`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    editUser(data){
        return instance.post(`editUser`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    createUser(data){
        return instance.post(`createUser`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    getWorkers(){
        return instance.get(`getWorkers?jwt=${jwt}`)
        .then (response => {
            return response.data;
        })
    },
    createWorker(data){
        return instance.post(`createWorker`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    deleteWorker(data){
        return instance.post(`deleteWorker`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    editWorker(data){
        return instance.post(`editWorker`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    getLogs(){
        return instance.get(`getLogs?jwt=${jwt}`)
        .then (response => {
            return response.data;
        })
    },
    getReports(){
        return instance.get(`getReports?jwt=${jwt}`)
        .then (response => {
            return response.data;
        })
    },
    editReport(data){
        return instance.post(`editReport`, {...data, jwt})
        .then (response => {
            return response.data;
        })
    },
    getJobs(){
        return instance.get(`getJobs?jwt=${jwt}`)
        .then (response => {
            return response.data;
        })
    }
}

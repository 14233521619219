import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import CustomizedInputs from '../../components/input/';
import {required, format} from 'redux-form-validators';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

let StaffForm = (props) => {

    const { handleSubmit, onReturn, addData, valid, dispatch } = props;

    const saveData = (data) => {
      if(valid){
        dispatch(reset('StaffForm'));
        props.addData(data);
      }
    }

    return(
      <Dialog
        open={props.modalStatus}
        onClose={() => props.closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Добавить сотрудника"}</DialogTitle>
        <form onSubmit={handleSubmit} autoComplete='off'>
        <DialogContent>
          <Field  name="name"
            component={CustomizedInputs}
            type="text"
            placeholder="ФИО"
            validate={required({msg: 'Это поле обязательно для заполнения'})}/>
          <Field  name="phone"
            component={CustomizedInputs}
            type="phone"
            placeholder="Номер телефона"
            validate={[required({msg: 'Это поле обязательно для заполнения'}), format({ with: /^[0-9\x20\x28\x29\-]{12,12}$/, message: 'Номер в формате 380661111111' })]}/>
          <Field  name="rate"
            component={CustomizedInputs}
            type="number"
            placeholder="Рейт, грн"
            validate={required({msg: 'Это поле обязательно для заполнения'})}/>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => props.closeModal()} 
          color="primary">
            Отменить
          </Button>
          <Button 
            onClick={handleSubmit(saveData)} 
            color="primary" autoFocus>
            Сохранить
          </Button>
        </DialogActions>
        </form>
      </Dialog>
        
    )
}

StaffForm = reduxForm({
    form: 'StaffForm', 
  })(StaffForm);

export default StaffForm;
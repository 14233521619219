export const localization = {
    header: {
      actions: ''
    },
    toolbar: {
      searchPlaceholder: 'Поиск',
      searchTooltip: 'Поиск'
    },
    body: {
      addTooltip: 'Добавить',
      deleteTooltip: 'Удалить',
      editTooltip: 'Редактировать',
      editRow: {
        deleteText: 'Вы уверены, что хотите удалить?',
        cancelTooltip: 'Отменить',
        saveTooltip: 'Подтвердить'
      }
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} c {count}',
        labelRowsSelect: 'колонки',
        nextTooltip: 'Дальше',
        previousTooltip: 'Назад',
        firstTooltip: 'На первую',
        lastTooltip: 'На последнюю'
    }
  }
import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import CustomizedInputs from '../../components/input';
import {required, length, email} from 'redux-form-validators';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

let UserForm = (props) => {
  const { handleSubmit, onReturn, addData, valid, dispatch } = props;

  const saveData = (data) => {
    if(valid){
      dispatch(reset('UserForm'));
      props.addData(data);
    }
  }

    return(
      <Dialog
        open={props.modalStatus}
        onClose={() => props.closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Добавить пользователя"}</DialogTitle>
        <form onSubmit={handleSubmit} autoComplete='off'>
        <DialogContent>
          <Field  name="name"
            component={CustomizedInputs}
            type="text"
            placeholder="ФИО"
            validate={required({msg: 'Это поле обязательно для заполнения'})}/>
          <Field  name="email"
            component={CustomizedInputs}
            type="email"
            placeholder="Email"
            validate={[required({msg: 'Это поле обязательно для заполнения'}), email({msg: 'Введите корректный email'})]}/>
          <Field  name="password"
            component={CustomizedInputs}
            type="password"
            placeholder="Пароль"
            validate={[required({msg: 'Это поле обязательно для заполнения'}), length({ minimum: 8, msg: 'Введите минимум 8 символов' })]}/>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => props.closeModal()} 
          color="primary">
            Отменить
          </Button>
          <Button 
            onClick={handleSubmit(saveData)} 
            color="primary" autoFocus>
            Сохранить
          </Button>
        </DialogActions>
        </form>
      </Dialog>
        
    )
}

UserForm = reduxForm({
    form: 'UserForm', 
  })(UserForm);

export default UserForm;
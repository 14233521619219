import React, {useState} from 'react';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import withWidth from '@material-ui/core/withWidth';
import {Switch, Route} from 'react-router-dom';

import Profile from './Profile';
import Navigation from './Navigation';
import Staff from '../staff/Staff';
import Users from '../users/Users';
import Logs from '../logs/Logs';
import Reports from '../reports/Reports';

const drawerWidth = 240;


const DefaultScreen = (props) => {

    const { classes, width } = props;

    const [drawer, setDrawer] = useState(false);

    return(
        <div className={classes.root}>
            <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawer && width !== 'sm' && width !== 'xs',
        })}
        color='inherit'
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {setDrawer(true)}}
            edge="start"
            className={clsx(classes.menuButton, drawer && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarContainer}>
            <Profile signOut={() => {console.log('signOut')}} login={'Test'}/>
          </div>
        </Toolbar>
      </AppBar>
        <Navigation
            open={drawer}
            handleDrawerClose={() => {setDrawer(false)}}
        />
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: drawer,
            })}>
            <div className={clsx(classes.row)}>
            <div className={classes.drawerHeader} />
            <Switch>
              <Route  path={'/reports'} render={() => <Reports/>}/>
              <Route  path={'/settings'} render={() => <Users/>}/>
              <Route  path={'/staff'} render={() => <Staff/>}/>
              <Route  path={'/logs'} render={() => <Logs/>}/>
              <Route  path={'/'} render={() => <Reports/>}/>
            </Switch>
            </div>
        </main>
    </div>
    )
}

const styles = theme => (
  {
    root: {
    display: 'flex',
    width: '100%',
    height: 'auto'
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0 10px 18px rgba(0,0,0,0.08)'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  toolbarContainer: {
      display: 'flex',
      flexGrow: '1',
      justifyContent: 'flex-end'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    width: `100%`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.up("md")]: {
        marginLeft: -drawerWidth,
    },
    minHeight: '100vh',
  },
  row: {
    padding: theme.spacing(3),
  },
  contentShift: {
    [theme.breakpoints.up("md")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  position: 'relative',
  minHeight: '100vh',
  });

export default withWidth()(withStyles(styles, { withTheme: true })(DefaultScreen));
import React, {useEffect, useState} from 'react';
import {actions} from '../../API';
import moment from 'moment';
import Loading from '../../components/Preloader';

import s from './logs.module.css';

let params = {
  name: 'Имя',
  phone: 'Номер телефона',
  email: 'Почту',
  rate: 'Рейт',
  bonus: 'Бонусы',
  comment: 'Комментарий',
  departmentName: 'Департамент',
  jobName: 'Вид работы',
  materials: 'Материалы',
  projectName: 'Проект',
  spentTimeLog: 'Время',
  telephoneNumber: 'Номер телефона',
  userName: 'Сотрудник',
  workDate: 'Дата подачи'
}

const Logs = (props) => {

  const [logs, setLogs] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        setIsLoad(true);
        actions.getLogs()
        .then(res => {
          setLogs(res);
          setIsLoad(false);
        })
        .catch(err => {
          setIsLoad(false);
        })
    }, [])

    moment().locale('uk').locale();

    const getTimeFromMins = (mins) => {
      let hours = Math.trunc(mins/60);
      let minutes = mins % 60;
        hours = hours < 10 ? '0'+hours : hours;
        minutes = minutes < 10 ? '0'+minutes : minutes;
      return hours+':'+minutes;
  };

    const getText = (data) => {
      let text = '';
      for(let item in data){
        if(params[item] !== undefined && data.spentTimeLog === undefined){
         text += `${params[item]}: ${data[item][0]} => ${data[item][1]}; ` 
        } else if(data.spentTimeLog !== undefined){
          let timeOne = (data.spentTimeLog[0].hours*60+data.spentTimeLog[0].minutes);
          let timeTwo = (data.spentTimeLog[1].hours*60+data.spentTimeLog[1].minutes);
          text += `${params[item]}: ${getTimeFromMins(timeOne)} => ${getTimeFromMins(timeTwo)}; ` 
        }
      }
      return text;
    }

    
    return(
        <div>
            {isLoad && <Loading/>}
            {logs
            .sort((a, b) => moment(b.date, 'DD-MM-YYYYTHH:mm:ssZ') - moment(a.date, 'DD-MM-YYYYTHH:mm:ssZ'))
            .map((item, index) => {
              return(
                <div className={s.log} key={index}>
                  <span>{`${moment(item.date).utc().format('DD.MM.yyyy')} ${moment(item.date).utc().format('HH:mm:ss')}`}:</span> <div>{` ${item.owner} ${item.operation} 
                   ${item.changed.email ? item.changed.email : item.changed.phone ? item.changed.phone : ''}`} {`${item.changed.telephoneNumber ? `${item.changed.telephoneNumber} ${item.changed.workDate}` : ''}`}  <p>{item.changeData ?  getText(item.changeData) : ''}</p></div>
                </div>
              )
            })}
        </div>
    )
}

export default Logs;
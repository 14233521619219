const UPDATE_USER = 'UPDATE-USER';

let initialState = {
    profile: null,
}

const User = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER: {
            return {
                ...state,
                profile: action.data
            }
        }
        default:
            return state
    }
}   

export const updateUser = (data) => ({type: UPDATE_USER, data});


export default User;
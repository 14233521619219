import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import { DateRange } from 'react-date-range';
import Loading from '../../components/Preloader';

import DateRageInput from './DateRage';

import { CSVLink, CSVDownload } from "react-csv";

import XLSX from 'xlsx'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import moment from 'moment';

import {actions} from '../../API';
import s from './reports.module.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import filterIcon from '../../img/filter.svg';
import csvIcon from '../../img/csv.svg';
import xlsxIcon from '../../img/xlsx.svg';



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '5px 10px 5px 7px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
PaperProps: {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
  },
},
};


  


function descendingComparator(a, b, orderBy) {

  let first = b[orderBy];
  let second = a[orderBy];

  if(orderBy === 'time'){
    first = b['spentTimeLog'].hours*60+b['spentTimeLog'].minutes;
    second = a['spentTimeLog'].hours*60+a['spentTimeLog'].minutes;
  }

  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'number', numeric: false, disablePadding: false, label: '', full: false},
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Дата подачи отчета', full: false},
  { id: 'workDate', numeric: false, disablePadding: false, label: 'Дата выполнения работ', full: false},
  { id: 'userName', numeric: false, disablePadding: false, label: 'Имя сотрудника', full: true },
  { id: 'projectName', numeric: false, disablePadding: false, label: '№ Проекта', full: true},
  { id: 'departmentName', numeric: false, disablePadding: false, label: 'Департамент', full: true},
  { id: 'jobName', numeric: false, disablePadding: false, label: 'Вид работ', full: true},
  { id: 'materials', numeric: false, disablePadding: false, label: 'Материалы', full: true},
  { id: 'time', numeric: false, disablePadding: false, label: 'Затрачено чч:мм', full: false},
  { id: 'price', numeric: true, disablePadding: false, label: 'Сумма, грн', full: false},
  { id: 'bonus', numeric: true, disablePadding: false, label: 'Бонусы, грн', full: false},
  { id: 'comment', numeric: false, disablePadding: false, label: 'Комментарий', full: true},
];


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth, padding: '3px 5px' }}
            className={headCell.full === true && classes.th}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{fontSize: 13}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 20vh)',
    '&.MuiTableCell-sizeSmall': {
      //minWidth: 350,
      whiteSpace: 'nowrap'
    }
  },
  tableCell: {
    '&.MuiTableCell-sizeSmall': {
      padding: 3,
      borderRight: '1px solid #e0e0e0',
      position: 'relative'
    }
  },
  th: {
    whiteSpace: 'nowrap',
    padding: 3,
    fontSize: 13
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  filterControl: {
    minWidth: 150,
    maxWidth: 150,
  },
  checkbox: {
    padding: 5,
    paddingRight: 10,
    fontSize: 14,
    },
  listItemText: {
      '& span': {
        fontSize: 14,
      }
   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  topLabel: {
    fontSize: 12,
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);

  const [openEdit, setOpenEdit] = React.useState(false);

  const [isData, setIsData] = useState([]);

  const [editData, setEditData] = useState(null);
  
  const [workers, setWorkers] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [departments, setDepartaments] = React.useState([]);
  const [typeWorks, setTypeWorks] = React.useState([]);

  const [activeDepartamens, setActiveDepartamens] = React.useState([]);
  const [activeProjects, setActiveProjects] = React.useState([]);
  const [activeTypesWork, setActiveTypesWork] = React.useState([]);

  const [openDateRange, setOpenDateRange] = useState(false);
  const [activeStaffs, setActiveStaffs] = React.useState([]);

  const [isLoad, setIsLoad] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);


  const [dateRange, setDateRenge] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const resetFilter = () => {
    setDateRenge([{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }]);
    setActiveStaffs([]);
    setActiveTypesWork([]);
    setActiveProjects([]);
    setActiveDepartamens([]);
  }

useEffect(() => {
  setIsLoad(true);
  actions.getReports()
    .then(res => {

      setIsData(res.map((item, index) => {
        return {...item, index: index+1}
      }));
      setIsLoad(false);
    })
    .catch(err => {
    });
  actions.getWorkers()
        .then(res => {
          let list = [];
          res.map(item => list.push({label: item.name, idWorker: item._id, id: item.phone}));
          setWorkers(list);
        })
        .catch(err => {
        })

        actions.getJobs()
        .then(res => {
          let projects = [];
          let departments = [];
          let jobs = [];

          for (let item in res){

            projects.push({
              label: res[item].name,
              id: res[item]._id
            });

            var idProject = res[item]._id;

            let departamentList = res[item].departments;

            let project = res[item].name;

            for (let item in departamentList){
              
              departments.push({
                label: `${departamentList[item].name} (${project})`,
                id: departamentList[item]._id,
                idProject: idProject
              });

              let jobsList = departamentList[item].jobs;
              let idDepartament = departamentList[item]._id;

              for (let item in jobsList){
                jobs.push({
                  label: `${jobsList[item].name} (${project})`,
                  id: jobsList[item]._id,
                  idDepartament: idDepartament
                })
              }
            }
          }

          setProjects(projects);
          setDepartaments(departments);
          setTypeWorks(jobs);
        })
        .catch(err => {
          console.log(err);
        })

         

}, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = isData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  
  const getTimeFromMins = (mins) => {
    let hours = Math.trunc(mins/60);
    let minutes = mins % 60;
      hours = hours < 10 ? '0'+hours : hours;
      minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours+':'+minutes;
};

const openEditModal = (type, data) => {
    let value = data[type];
    if(type === 'spentTimeLog'){
      let time = value.hours*60+value.minutes;
      value = getTimeFromMins(time);
    } else if (type === 'editProject') {
      let pr = isData.find(it => it._id === data._id);
      value = {
        jobId: pr.jobId ? pr.jobId : '',
        projectId: pr.projectId ? pr.projectId : '',
        departmentId: pr.departmentId ? pr.departmentId : ''
      }
    }
    setEditData({
      id: data._id,
      type,
      value: value
    });
    setOpenEdit(true);
}


const renderDate = (date) => {
  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          id="date-picker-inline"
          label="Дата выполнения работ"
          format="MM/dd/yyyy"
          value={moment(date, 'DD-MM-YYYYTHH:mm:ssZ').format('MM/DD/yyyy')}
          className={classes.formControl}
          onChange={(date) => {setEditData({...editData, value: moment(date)})}}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
       </MuiPickersUtilsProvider>
  )
}

const  RenderSelect = () => {
  
  let data = [];

  let label = '';

  if(editData.type === 'telephoneNumber'){
    data = workers;
    label = 'Сотрудник'
  }
   
      return(
        <FormControl className={classes.formControl}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={editData.value}
          onChange={(e) => setEditData({...editData, value: e.target.value})}
        >
        {data.map((item, index) => {
          return(
            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
          )
          })}
        </Select>
       </FormControl>
      )
}

const RenderSelectProject = () => {
  return(
    <>
    <FormControl className={classes.formControl}>
        <InputLabel id="select-label">{'Проект'}</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={editData.value.projectId}
          onChange={(e) => setEditData({
            ...editData, 
            value: {
            ...editData.value,
            projectId: e.target.value,
            departmentId: '',
            jobId: ''
          }})}
        >
        {projects
        .map((item, index) => {
          return(
            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
          )
          })}
        </Select>
    </FormControl>
    <FormControl className={classes.formControl}>
        <InputLabel id="select-label">{'Департамент'}</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={editData.value.departmentId}
          onChange={(e) => setEditData({
            ...editData, 
            value: {
            ...editData.value,
            departmentId: e.target.value,
            jobId: ''
          }})}
          >
        {departments
        .filter(it => new Set([editData.value.projectId]).has(it.idProject))
        .map((item, index) => {
          return(
            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
          )
          })}
        </Select>
    </FormControl>
    <FormControl className={classes.formControl}>
        <InputLabel id="select-label">{'Вид работы'}</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={editData.value.jobId}
          onChange={(e) => setEditData({
            ...editData, 
            value: {
            ...editData.value,
            jobId: e.target.value
          }})}
        >
        {typeWorks
        .filter(it => new Set([editData.value.departmentId]).has(it.idDepartament))
        .map((item, index) => {
          return(
            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
          )
          })}
        </Select>
    </FormControl>
    </>
  )
}

const RenderInput = (label, type, multiline) => {
  let value = editData.value;
  return(
    <TextField className={classes.formControl} id="standard-basic" value={value} label={label} type={type}
          onChange={(e) => setEditData({...editData, value: e.target.value})}
          multiline={multiline}
          rowsMax={4} />
  )
}

const saveData = () => {
  setIsLoad(true);
    let index = isData.findIndex(it => it._id === editData.id),
        value = editData.value,
        updateData = null,
        editStatus = null

    if(editData.type === 'workDate'){
      updateData = {
        [editData.type]: moment(value).format('DD.MM.yyyy'),
      };
      editStatus = {
        [editData.type]: true
      };
    } else if (editData.type === 'spentTimeLog'){
        let time = value.split(':');
        updateData = {
          [editData.type]: {
            hours: Number(time[0]),
            minutes: Number(time[1])
          }
        }
        editStatus = {
          [editData.type]: true
        }
    } else if(editData.type === 'telephoneNumber') {
        let newUser = workers.find(it => it.id === value);
        updateData = {
          [editData.type]: value,
          userName: newUser.label,
        } 
        editStatus = {
          [editData.type]: true,
           userName: true
        }
    } else if (editData.type === 'editProject') { 

      let projectName = projects.find(it => it.id === value.projectId);
      let jobName = typeWorks.find(it => it.id === value.jobId);
      let departmentName = departments.find(it => it.id === value.departmentId);

      updateData = {
        ...value,
        projectName: projectName ? projectName.label : '',
        jobName: jobName ? jobName.label : '',
        departmentName: departmentName ? departmentName.label : '',
      }
      editStatus = {
        projectName: true,
        jobName: true,
        departmentName: true,
      }
    }
    else {
      updateData = {
        [editData.type]: value
      };
      editStatus = {
        [editData.type]: true
      } 
    }

    let data = {
      id: editData.id,
      edit: updateData
    }

    actions.editReport(data)
      .then(res => {
        isData[index] = {
          ...isData[index],
          ...updateData,
          editData: {
            ...isData[index].editData,
            ...editStatus
          }
        };
        setIsLoad(false);
        
        setOpenEdit(false);
      })
      .catch(err => {
        setOpenEdit(false);
        setIsLoad(false);
      })

}

const deleteReport = (id) => {

  setDeleteId(id);
  setConfirmDelete(true);

 /* let index = isData.findIndex(it => it._id === id);
  const dataDelete = [...isData];
  
  actions.deleteReport({id})
  .then(res => {
    if (index > -1) {
      dataDelete.splice(index, 1);
      setIsData([...dataDelete])
     }
    console.log(res, 'res');
  })
  .catch(err => {
    console.log(err, 'res');
  })*/
}

const renderFilter = () => {
  return(
  <div className={s.container}>
  <div className={s.filtersContainer}>
  <div className={s.wrapper}>
    <DateRageInput open={setOpenDateRange} label={'Период'}
          date={`${String(moment(dateRange[0].startDate).format('yyyy/MM/DD'))}-${String(moment(dateRange[0].endDate).format('yyyy/MM/DD'))}`}/>
    { openDateRange && <div className={s.dateRange}>
      <DateRange
      onChange={item => {
          setDateRenge([item.selection]);
        }}
      ranges={dateRange}
      dragSelectionEnabled={true}
      moveRangeOnFirstSelection={false}

    />
    <div className={s.confirm} onClick={() => setOpenDateRange(false)}>Подтвердить</div>
    </div>
    }
  </div>
    <div className={s.wrapper}>
      <FormControl className={classes.filterControl}>
        <InputLabel shrink id="workers">Сотрудники</InputLabel>
        <Select
          labelId="workers"
          id="workers-checkbox"
          multiple
          value={activeStaffs}
          onChange={(e) => setActiveStaffs(e.target.value)}
          input={<BootstrapInput />}
          renderValue={(activeStaffs) => activeStaffs.map(it => `${workers.find(item => item.id === it).label} `)}
          MenuProps={MenuProps}
          disabled={props.disabled}
        >
          {workers.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              <Checkbox className={classes.label} checked={activeStaffs.indexOf(item.id) > -1}  color="primary"/>
              <ListItemText className={classes.listItemText} primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    <div className={s.wrapper}>
    <FormControl className={classes.filterControl}>
    <InputLabel shrink id="projects">Проекты</InputLabel>
    <Select
      labelId="projects"
      id="projects-checkbox"
      multiple
      value={activeProjects}
      onChange={(e) => setActiveProjects(e.target.value)}
      input={<BootstrapInput />}
      renderValue={(activeProjects) => activeProjects.map(it => `${projects.find(item => item.id === it).label} `)}
      MenuProps={MenuProps}
      disabled={props.disabled}
    >
      {projects.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          <Checkbox className={classes.label} checked={activeProjects.indexOf(item.id) > -1}  color="primary"/>
          <ListItemText className={classes.listItemText} primary={item.label} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
    </div>
    <div className={s.wrapper}>
    <FormControl className={classes.filterControl}>
    <InputLabel shrink id="projects">Департамены</InputLabel>
    <Select
      labelId="projects"
      id="projects-checkbox"
      multiple
      value={activeDepartamens}
      onChange={(e) => setActiveDepartamens(e.target.value)}
      input={<BootstrapInput />}
      renderValue={(activeDepartamens) => activeDepartamens.map(it => `${departments.find(item => item.id === it).label} `)}
      MenuProps={MenuProps}
      disabled={activeProjects.length === 0 ? true : false}
    >
      {departments
      .filter(it => new Set(activeProjects).has(it.idProject))
      .map((item, index) => (
        <MenuItem key={index} value={item.id}>
          <Checkbox className={classes.label} checked={activeDepartamens.indexOf(item.id) > -1}  color="primary"/>
          <ListItemText className={classes.listItemText} primary={item.label} />
        </MenuItem>
      ))}
    </Select>
    </FormControl>
    </div>
    <div className={s.wrapper}>
    <FormControl className={classes.filterControl}>
    <InputLabel shrink id="projects">Вид работы</InputLabel>
    <Select
      labelId="projects"
      id="projects-checkbox"
      multiple
      value={activeTypesWork}
      onChange={(e) => setActiveTypesWork(e.target.value)}
      input={<BootstrapInput />}
      renderValue={(activeTypesWork) => activeTypesWork.map(it => `${typeWorks.find(item => item.id === it).label} `)}
      MenuProps={MenuProps}
      disabled={activeDepartamens.length === 0 ? true : false}
    >
      {typeWorks
      .filter(it => new Set(activeDepartamens).has(it.idDepartament))
      .map((item, index) => (
        <MenuItem key={index} value={item.id}>
          <Checkbox className={classes.label} checked={activeTypesWork.indexOf(item.id) > -1}  color="primary"/>
          <ListItemText className={classes.listItemText} primary={item.label} />
        </MenuItem>
      ))}
    </Select>
    </FormControl>
    </div>
  </div>
  <div className={s.control}>
      <div className={s.icon} onClick={() => resetFilter()}><img src={filterIcon}/></div>
           <div className={s.icon} onClick={() => {
             var worksheet = XLSX.utils.aoa_to_sheet(csv);
             var new_workbook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
             XLSX.writeFile(new_workbook, "bot.xlsx")
           }}><img src={xlsxIcon}/></div>
      <CSVLink filename={"bot.csv"}  data={csv}><div className={s.icon}><img src={csvIcon}/></div></CSVLink>
      </div>
  </div>
  )
}


let sortData = stableSort(isData, getComparator(order, orderBy))
.filter(it => moment(it.workDate, 'DD-MM-YYYYTHH:mm:ssZ').format('yyyy-MM-DD') <= moment(dateRange[0].endDate).format('yyyy-MM-DD') && moment(it.workDate, 'DD-MM-YYYYTHH:mm:ssZ').format('yyyy-MM-DD') >= moment(dateRange[0].startDate).format('yyyy-MM-DD'))
.filter(activeStaffs.length > 0 ? it => new Set(activeStaffs).has(it.telephoneNumber) : it => it)
.filter(activeProjects.length > 0 ? it => new Set(activeProjects).has(it.projectId) : it => it)
.filter(activeDepartamens.length > 0 ? it => new Set(activeDepartamens).has(it.departmentId) : it => it)
.filter(activeTypesWork.length > 0 ? it => new Set(activeTypesWork).has(it.jobId) : it => it);


let csvData = sortData.map((row, index) => [
  index+1, 
  `${moment(row.createdAt).utc().format('DD.MM.yyyy')} ${moment(row.createdAt).utc().format('HH:mm:ss')}`,
moment(row.workDate, 'DD-MM-YYYYTHH:mm:ssZ').format('DD.MM.yyyy'),
row.userName,
row.projectName,
row.departmentName,
row.jobName ? row.jobName : 'Не указан',
row.materials ? row.materials : 'Не указан',
getTimeFromMins(row.spentTimeLog.hours*60+row.spentTimeLog.minutes).replace(':', ','),
row.rate ? row.rate/60*row.spentTimeLog.hours*60+row.spentTimeLog.minutes : 0,
row.bonus ? row.bonus : 0,
row.comment ? row.comment : 'Не указан'
]);

let totalTime = getTimeFromMins(sortData.reduce((a,b) => {
  let time = (b.spentTimeLog.hours*60+b.spentTimeLog.minutes);
  return a + (Number(time) || 0)
  }, 0));

let totalAmount = sortData.reduce((a,b) => {
  let time = (b.spentTimeLog.hours*60+b.spentTimeLog.minutes);
  let rate = b.rate/60;
  return a + (b.rate ? Number(rate)*Number(time) : 0 || 0)
  }, 0);
let totalBonus =  sortData.reduce((a,b) => {                          
  return a + (Number(b.bonus) || 0)
}, 0);


let csv = [
  ['№', 'Дата подачи отчета', 'Дата выполнения работ', 'Имя сотрудника', '№ Проекта', 'Департамент', 'Вид работ', 'Материалы', 'Выполняемое время чч:мм', 'Сумма оплаты труда,грн', 'Бонусы', 'Комментарий'],
  ...csvData,
  ['', '', '', '', '', '', '', '', totalTime, totalAmount, totalBonus, ''],
]


const confirm = () => {
  let index = isData.findIndex(it => it._id === deleteId);
  const dataDelete = [...isData];
  setIsLoad(true);
  actions.deleteReport({id: deleteId})
  .then(res => {
    if (index > -1) {
      dataDelete.splice(index, 1);
      setIsData([...dataDelete])
     }
     setConfirmDelete(false);
     setDeleteId(null);
     setIsLoad(false);
  })
  .catch(err => {
    setConfirmDelete(false);
    setDeleteId(null);
    setIsLoad(false);
  })
}

const confirmModal = () => {
  return(
  <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Удаление"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Вы действительно хотите удалить отчет?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Отменить
          </Button>
          <Button onClick={() => confirm()} color="primary" autoFocus>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      )
}


  return (
    <div className={classes.root}>
    {isLoad &&  <Loading/>} 
      {confirmModal()}
    <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Редактировать"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {editData ? editData.type === 'workDate' ? renderDate(editData.value) 
            : editData.type === 'telephoneNumber' ? RenderSelect() 
            : editData.type === 'bonus' ? RenderInput('Бонус', 'number') 
            : editData.type === 'comment' ? RenderInput('Комментарий', 'text', true)
            : editData.type === 'materials' ? RenderInput('Материалы', 'text', true)
            : editData.type === 'spentTimeLog' ? RenderInput('Время', 'time')
            : editData.type === 'editProject' ? RenderSelectProject()
            : '' 
            : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)} color="primary">
            Отменить
          </Button>
          <Button onClick={() => saveData()} color="primary" autoFocus>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>

      <div className={s.topHeader}>
      {renderFilter()}
      
      </div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            //aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
            fixedHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={isData.length}
            />
            <TableBody>
              { sortData
                .map((row, index) => {
                  let time = (row.spentTimeLog.hours*60+row.spentTimeLog.minutes);
                  let rate = row.rate/60;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell className={classes.tableCell} onClick={() => deleteReport(row._id)}   align="left"><div className={s.delete}><span>{index+1}</span></div></TableCell>
                      <TableCell className={classes.tableCell} align="left">{`${moment(row.createdAt).utc().format('DD.MM.yyyy')} ${moment(row.createdAt).utc().format('HH:mm:ss')}`}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('workDate', row)} align="left">{moment(row.workDate, 'DD-MM-YYYYTHH:mm:ssZ').format('DD.MM.yyyy')} {row.editData.workDate && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('telephoneNumber', row)} align="left">{workers.find(it => it.id === row.telephoneNumber) ? workers.find(it => it.id === row.telephoneNumber).label : row.userName }  {row.editData.telephoneNumber && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('editProject', row)} align="left">{row.projectName} {row.editData.projectName && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('editProject', row)} align="left">{row.departmentName} {row.editData.departmentName && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('editProject', row)} align="left">{row.jobName} {row.editData.jobName && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('materials', row)} align="left">{row.materials ? row.materials : 'Не указан'} {row.editData.materials && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('spentTimeLog', row)} align="left">{getTimeFromMins(time)} {row.editData.spentTimeLog && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.rate ? Number(rate*time).toFixed(2) : 0} {row.editData.rate && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('bonus', row)} align="left">{row.bonus ? Number(row.bonus).toFixed(2) : 0} {row.editData.bonus && <div className={s.edit}/>}</TableCell>
                      <TableCell className={classes.tableCell} onClick={() => openEditModal('comment', row)} align="left">{row.comment ? row.comment : 'Не указан'} {row.editData.comment && <div className={s.edit}/>}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={s.sumContainer}>
                В общей сумме
          <span>Время: {totalTime}</span>
          <span>Сумма: {totalAmount.toFixed(2)}</span>
          <span>Бонусы: {totalBonus.toFixed(2)}</span>
          <span>Итог: {Number(totalBonus.toFixed(2))+Number(totalAmount.toFixed(2))}</span>
        </div>
      </Paper>
      
    </div>
  );
}

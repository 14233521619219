import React, {useState} from 'react';
import s from './login.module.css';
import Button from '../../components/Button';
import LoginForm from './LoginForm';
import ResetForm from './ResetForm';
import {actions} from '../../API';
import {updateUser} from '../../reducers/user';

import { useDispatch, useSelector } from "react-redux";


const Login = (props) => {

    const dispatch = useDispatch();

    const [load, setLoad] = useState(false);

    const [reset, setReset] = useState(false);

    const login = (data) => {
        setLoad(true);
        actions.auth(data)
            .then(res => {
                if(res.token){
                    localStorage.setItem('@user', JSON.stringify(res));
                    setTimeout(() => {
                        dispatch(updateUser(res));
                    }, 500)
                } else {
                    alert(res.message)
                }
                setLoad(false);
            })
            .catch(err => {
                alert('При авторизации произошла ошибка, попробуйте ещё раз')
                setLoad(false);
            })
        }

        const resetPass = (data) => {
            setLoad(true);
            actions.reset(data)
                .then(res => {
                    alert(res.message);
                    setLoad(false);
                })
                .catch(err => {
                    alert('При сбросе произошла ошибка, попробуйте ещё раз')
                    setLoad(false);
                })
        }

        const statusRest = () => {
            setReset(reset ? false : true);
        }

    return(
        <div className={s.containerForm}>
            <div className={s.row}>
               {!reset ?  <LoginForm login={login} load={load} statusRest={statusRest}/> :
                <ResetForm resetPass={resetPass} load={load} statusRest={statusRest}/> 
                }
            </div>
        </div>
    )
}

export default Login;
import React, {useEffect} from 'react';
import './App.css';

import Login from './scenes/login/Login';
import Reset from './scenes/Reset/Reset';

import DefaultScreen from './scenes/DefaultScreen/Default';
import {updateUser} from './reducers/user';

import { useDispatch, useSelector } from "react-redux";


const App = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => ({
    user: state.user.profile,
  }));

  useEffect(() => {
    let saveUser = JSON.parse(localStorage.getItem('@user'));
      if(saveUser){
        dispatch(updateUser(saveUser));
      }
  }, []);
 

  return (
    <>
       {user ? <DefaultScreen/> : <Login/>} 
    </>
  );
}
  

export default App;

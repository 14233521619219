import React from 'react';
import Loading from './Loading';

const Preloader = () => {
    return(
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1,
            background: 'rgb(255 255 255 / 62%)',
            top: 0,
            bottom: 0,
            legt: 0,
            right: 0,
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
                <Loading size={32} color='rgb(50 109 255)'/>
        </div>
    )
}

export default Preloader;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
      position: 'absolute',
      right: 18,
      bottom: 18,
      zIndex: 9999
  },
  color: {
    backgroundColor: '#326dff'
  }
}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab onClick={props.handleClick} className={classes.color} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </div>
  );
}
